import styled from "@emotion/styled";
import { Card } from "@mui/material";

export interface LoadingSkeletonProps {
	isLoading?: boolean;
}

export const LineItemBackground = styled(Card, {
	shouldForwardProp: (prop) => prop !== "isLoading"
})<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "none" : "grid",
	backgroundColor: "var(--gray-50)",
	borderRadius: "unset",
	padding: 16
}));

export const LineItemAccordionDetails = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 8,
	padding: 16
});

const FIRST_ROW = "& > div:nth-of-type(1)";
const FIRST_ROW_DETAILS_COLUMNS = "& > div:nth-of-type(n + 2)";
const getFirstRowModificationsSelector = (columns: number) => `& > div:nth-of-type(n + ${columns + 1})`;
const MODIFICATION_ROWS = FIRST_ROW_DETAILS_COLUMNS;
export const SUBGRID_TEMPLATE = {
	display: "grid",
	gridColumn: "1 / -1",
	gridTemplateColumns: "subgrid"
};

interface LineItemGridContainerProps {
	mainContentColumnCount: number;
}
export const LineItemGridContainer = styled.div<LineItemGridContainerProps>(({ theme, mainContentColumnCount }) => ({
	color: theme.project.colors.fontColors.secondary,
	display: "grid",
	gridTemplateColumns: "repeat(8, 1fr)",
	gap: 24,
	[FIRST_ROW]: {
		...SUBGRID_TEMPLATE,
		[FIRST_ROW_DETAILS_COLUMNS]: {
			color: theme.project.colors.fontColors.main,
			fontSize: "var(--font-size-3)",
			fontWeight: 500,
			lineHeight: "var(--line-height-8)",
			gridColumn: "span 1",
			div: {
				color: theme.project.colors.fontColors.secondary,
				fontWeight: 400,
				fontSize: "var(--font-size-3)",
				"&:nth-of-type(1)": {
					color: theme.project.colors.fontColors.main,
					fontWeight: 500
				}
			}
		},
		[getFirstRowModificationsSelector(mainContentColumnCount)]: {
			display: "none" // Swap with the copy outside the first row
		}
	},
	[MODIFICATION_ROWS]: {
		// Set up divs after the first row container to all be subgrid
		...SUBGRID_TEMPLATE,
		rowGap: 8
	},
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		[FIRST_ROW]: {
			rowGap: 8,
			[FIRST_ROW_DETAILS_COLUMNS]: {
				display: "flex",
				gap: 16,
				[`&:nth-of-type(${mainContentColumnCount})`]: {
					alignItems: "center",
					flexDirection: "column"
				}
			},
			[getFirstRowModificationsSelector(mainContentColumnCount)]: {
				flexDirection: "column",
				gap: 8,
				gridRow: 2
			}
		},
		[MODIFICATION_ROWS]: {
			display: "none" // Swap with the copy inside the first row
		}
	}
}));

export const LineItemSkeletonContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "isLoading"
})<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "grid" : "none",
	gridTemplateColumns: "5fr 1fr 1fr 1fr",
	gap: "16px"
}));

export const LineItemLeftSkeletons = styled.div({
	gridTemplateRows: "2fr"
});

export const LineItemRightSkeletons = styled.div({
	gridTemplateColumns: "repeat(3, 1fr)"
});

export const LineItemErrorMessage = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	display: "flex",
	alignItems: "center",
	color: "var(--red-700)",
	gap: "8px",
	paddingBottom: "8px",
	svg: {
		width: 16,
		height: 16,
		margin: 0
	}
}));
